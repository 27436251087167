<template>
  <div
    id="container"
    :class="{
      'animated-ideology-background': isRunning,
      'linear-background': !isRunning,
    }"
  >
    <div id="generator">
      <h1>İdeoloji Robotu</h1>
      <h2>
        <span
          :style="{ color: word.color }"
          :key="word.text + Math.random()"
          v-for="word in colorizedIdeology"
        >
          {{ word.text }}
        </span>
      </h2>
      <div class="buttons">
        <button @click="generateIdeology">Şipşak</button>
        <button @click="generateIdeologyWithSlot">
          Kendimi şanslı hissediyorum
        </button>
        <button @click="inform">?</button>
      </div>
    </div>
  </div>
</template>

<script>
import WordList from './data/data.json'

export default {
  name: 'App',
  data() {
    return {
      isRunning: false,
      ideology: '',
      chaos: 0,
      colorizedIdeology: {},
      nextColorIndex: 0,
      colors: [
        '#0067ff',
        '#c8007f',
        '#914ee7',
        '#ca1fe2',
        '#4a3bff',
        '#454db8',
        'green',
      ],
      templates: [
        '[prefix] [noun]',
        '[prefix] [adjective] [noun]',
        '[prefix] [adjective] [noun]', // chance plus
        '[noun] [suffix] [prefix] [adjective] [noun]',
        '[noun] [suffix] [prefix] [adjective] [noun]', // chance plus
        '[adjective] [noun] [suffix] [prefix] [prefix] [noun]',
        '[adjective] [noun] [suffix] [prefix] [prefix] [noun] [suffix] [noun]',
      ],
    }
  },
  mounted() {
    //initialization
  },
  computed: {},
  methods: {
    inform() {
      alert(
        'Bu robot, kelimeleri rastgele bir araya getirerek sonuçlar üretiyor. Şipşak butonu hemen bir tane oluşturur. Kendimi şanslı hissediyorum butonu slot gibi çalışır.'
      )
    },
    nextColor() {
      const maxIndex = this.colors.length - 1
      this.nextColorIndex += 1

      if (this.nextColorIndex > maxIndex) this.nextColorIndex = 0
      return this.colors[this.nextColorIndex]
    },
    getRandomFromList(list) {
      const result = Math.floor(Math.random() * list.length)
      return list[result]
    },
    getRandomItemFromDataList(listName) {
      const list = WordList[listName]
      return this.getRandomFromList(list)
    },
    renderTemplate(template, keepBrackets = false) {
      const templateWords = template.match(/\[\w+\]/g)
      let result = template

      for (const word of templateWords) {
        const wordType = word.replace(/\[(\w+)\]/, '$1')
        const generatedWord = this.getRandomItemFromDataList(wordType)
        const toReplace = keepBrackets ? wordType : word
        result = result.replace(toReplace, generatedWord)
      }
      return result
    },
    tokenizeIdeology(ideology) {
      const tokens = []
      const words = []
      let tempString = ''
      for (const s of ideology) {
        if (s === '[') {
          // opening
          if (tempString) tokens.push(tempString)
          tempString = ''
        } else if (s === ']') {
          // closing
          tokens.push(tempString)
          words.push(tempString)
          tempString = ''
        } else {
          tempString += s
        }
      }
      return { tokens, words }
    },
    colorizeIdeology(tokenizedIdeology) {
      const tokenResult = []
      for (const token of tokenizedIdeology.tokens) {
        let color = ''
        if (tokenizedIdeology.words.includes(token)) {
          color = this.nextColor()
        }
        tokenResult.push({ text: token, color: color })
      }
      return tokenResult
    },
    generateIdeology() {
      const template = this.getRandomFromList(this.templates)
      let newIdeology = this.renderTemplate(template, true)
      const tokenizedIdeology = this.tokenizeIdeology(newIdeology)
      this.colorizedIdeology = this.colorizeIdeology(tokenizedIdeology)
    },
    generateIdeologyWithSlot() {
      this.isRunning = true
      for (let i = 1; i <= 60; i++) {
        setTimeout(() => {
          this.generateIdeology()
          if (i === 60) this.isRunning = false
        }, i ** 2.1)
      }
    },
  },
}
</script>

<style lang="scss">
* {
  margin: 0px;
  padding: 0px;
  text-transform: lowercase;
  font-family: monospace;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
}

.linear-background {
  background: linear-gradient(181deg, #914ee7, #402bfa);
  background-size: 200% 200%;
}

#container {
  display: flex;
  min-height: 100vh;
}

button {
  border: 2px solid #a070df;
  border-radius: 10px;
  padding: 5px 15px;
  color: #fff;
  // background: linear-gradient(11deg, #ff7171, #c8007f);
  background: linear-gradient(10deg, #8648d6, #807de3);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  margin: 10px;

  &:focus {
    outline: none;
  }

  &:active {
    transform: scale(0.95);
    background: linear-gradient(20deg, #7031c2, #807de3);
  }
}

#generator {
  display: flex;
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  flex-direction: column;
  align-self: center;
  justify-content: space-between;
  height: 50vh;
  width: 40vw;
  max-width: 500px;
  border-radius: 15px 0;
  background-color: rgba(255, 255, 255, 0.452);
  padding: 25px;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;

  .buttons {
    // align-items: end;
  }

  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  #generator {
    height: 60vh;
    width: 50vw;
  }
}

@media (max-width: 768px) {
  #generator {
    height: 65vh;
    width: 85vw;

    .buttons button {
      font-size: 12px;
      margin: 5px;
      width: 100%;
    }
  }
}

.animated-ideology-background {
  background: linear-gradient(
    181deg,
    #ff7171,
    #914ee7,
    #ca1fe2,
    #0067ff,
    #fa7fd9,
    #914ee7
  );
  background-size: 1100% 1100%;

  -webkit-animation: ideologyAnimation 4s ease infinite;
  -moz-animation: ideologyAnimation 4s ease infinite;
  -o-animation: ideologyAnimation 4s ease infinite;
  animation: ideologyAnimation 4s ease infinite;
}

@-webkit-keyframes ideologyAnimation {
  0% {
    background-position: 0% 96%;
  }
  50% {
    background-position: 100% 5%;
  }
  100% {
    background-position: 0% 96%;
  }
}
@-moz-keyframes ideologyAnimation {
  0% {
    background-position: 0% 96%;
  }
  50% {
    background-position: 100% 5%;
  }
  100% {
    background-position: 0% 96%;
  }
}
@-o-keyframes ideologyAnimation {
  0% {
    background-position: 0% 96%;
  }
  50% {
    background-position: 100% 5%;
  }
  100% {
    background-position: 0% 96%;
  }
}
@keyframes ideologyAnimation {
  0% {
    background-position: 0% 96%;
  }
  50% {
    background-position: 100% 5%;
  }
  100% {
    background-position: 0% 96%;
  }
}
</style>
